import type { GetStaticProps, NextPage } from "next";
import {
  Flex,
  Text,
  Button,
  Box,
  Heading,
  Image,
  Card,
  CardBody,
  CardHeader,
  SimpleGrid,
  Avatar,
  WrapItem,
  Link,
  Stack,
  StackDivider,
  Icon,
  Container,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { Section } from "../components/section";
import LandingFaq from "../components/landing-faq";
import { Features } from "../components/features";
import { ImQuotesLeft } from "react-icons/im";
import { Product } from "./api/plans";
import { getProducts } from "./api/plans/get-products";
import { useLocalStorage } from "../hooks/use-local-storage";
import { billingSelectionKey, promotionCodeIdKey } from "./billing";
import { useRouter } from "next/router";
import sendSystemAlert from "../server/email/system-alert";
import PricingSwitch from "../components/pricing-switch";
import { siteConfig } from "../configuration/config";
import SpinnerFull from "../components/spinner-full";
import { useEffect } from "react";
import PolandModal from "../components/country-modals/poland-modal";
import FeatureCheckList from "../components/feature-check-list";
import FeaturePresentation from "../components/feature-presentation";
//import { SenjaTestimonials } from "../components/senja-testimonials";
import { logger } from "../utils/logger";
import dynamic from "next/dynamic";

const LanguageSelector = dynamic(
  () => import("../components/language-selector-landing"),
  {
    ssr: false,
    loading: () => <Spinner />,
  }
);

const YoutubeEmbed = dynamic(() => import("../components/youtube-embed"), {
  ssr: false,
  loading: () => <Spinner />,
});

const SenjaTestimonials = dynamic(
  () => import("../components/senja-testimonials"),
  {
    ssr: false,
    loading: () => <Spinner />,
  }
);

interface Props {
  products: Product[] | null;
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  try {
    const { products } = await getProducts();

    return {
      props: {
        products: products,
      },
      revalidate: 86400, // 24 hours
    };
  } catch (error: any) {
    console.error(error);
    await sendSystemAlert(
      "Failed to fetch plans on landing page. " + error?.message
    );
  }

  return {
    props: {
      products: null,
    },
    revalidate: 300, // retry in 5 min
  };
};

const LandingPage: NextPage<Props> = (props: Props) => {
  const router = useRouter();
  const { setStorageData: setBillingSelection } =
    useLocalStorage<string>(billingSelectionKey);

  const {
    storageData: promotionCodeId,
    setStorageData: setPromotionCodeId,
    isLoadingStorage: isLoadingPromotionCodeId,
  } = useLocalStorage<string>(promotionCodeIdKey);

  // useEffect(() => {
  //   if (isLoadingPromotionCodeId) {
  //     return;
  //   }

  //   setPromotionCodeId(promotionCodes[0].code);
  // }, [isLoadingPromotionCodeId, setPromotionCodeId]);

  const onSignupClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    logger.info("On Signup");
    router.push({
      pathname: "/authentication/signup",
      query: { forwardSignup: true },
    });
  };

  useEffect(() => {
    if (router && siteConfig.overrideLanding) {
      router.push("/authentication/signin");
    }
  }, [router]);

  const AvatarItem = (props: {
    image: string;
    name: string;
    country: string;
  }) => (
    <WrapItem flexDir={"column"} alignItems="center" mx={2}>
      <Avatar
        size="2xl"
        name={props.name}
        src={`/avatars/microsoft-server-speech-text-to-speech-voice--${props.image}`}
      />
      <Text fontSize="lg" mt={1} color="white">
        {props.name}
      </Text>
      <Text fontSize="xs" fontWeight={"bold"} color="brand.gray.100">
        {props.country}
      </Text>
    </WrapItem>
  );

  const TestimonialItem = (props: {
    text: string;
    author: string;
    link: string;
    borderColor: string;
  }) => (
    <Card mb="5" borderTop={`5px solid ${props.borderColor}`}>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          <Box>
            <Flex>
              <Icon
                color={props.borderColor}
                as={ImQuotesLeft}
                fontSize="2xl"
                mr="5"
              />
              <Heading
                fontSize="md"
                fontWeight={"normal"}
                lineHeight={1.6}
                color="brand.gray.600"
              >
                {props.text}
              </Heading>
            </Flex>
            <Text pt="2" fontSize="xs" color="brand.gray.600" textAlign="right">
              <Link
                href={props.link}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {props.author}
              </Link>
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );

  if (siteConfig.overrideLanding) {
    return <SpinnerFull />;
  }

  return (
    <Box>
      <PolandModal />
      <Section>
        <Flex flexDir="column" flex="1">
          <Heading
            as="h1"
            color={"brand.gray"}
            fontSize={{ base: "6xl", lg: "38px", xl: "6xl" }}
            fontWeight="bold"
            lineHeight={{ base: "4rem", lg: "2.5rem", xl: "4rem" }}
            letterSpacing={".001rem"}
            mt={{ base: "30px", xl: "70px" }}
          >
            Practice{" "}
            <Box
              as="span"
              bgGradient={"linear-gradient(to right, #f6d78b, #7f64b0)"}
              backgroundClip="text"
            >
              Language
            </Box>{" "}
            <Box as="span">with AI</Box>
          </Heading>
          <Text mt={5}>
            The ultimate language training app that uses AI technology to help
            you improve your oral language skills.
          </Text>

          <FeatureCheckList />

          <Button
            colorScheme={"brand.primary"}
            color={"brand.gray"}
            maxW={{ base: "100%", sm: "300px" }}
            onClick={onSignupClick}
          >
            7 days free
          </Button>
          <Text
            maxW={{ base: "100%", sm: "300px" }}
            textAlign="center"
            fontSize="xs"
            mt={1}
          >
            Credit card required - cancel any time
          </Text>
        </Flex>
        <Flex
          display={{ base: "none", lg: "block" }}
          flex="1"
          justifyContent={{ base: "center" }}
        >
          <Image
            src="/landing-1.jpeg"
            alt="Landing"
            alignSelf={"center"}
            width={{ base: "250px", sm: "350px", md: "450px", xl: "100%" }}
          />
        </Flex>
      </Section>

      <Box
        backgroundColor="brand.gray.100"
        color="brand.gray.600"
        textAlign={"center"}
        p={7}
        my={7}
        fontSize={"sm"}
      >
        <Flex justifyContent={"center"} alignItems="center">
          <Image
            src="/vector/laurel-left.svg"
            alt="laurel left"
            w="50px"
            mr={3}
            opacity={0.3}
          />
          <Box>
            <Text fontSize={{ base: "2xs", sm: "xs", md: "md" }}>
              From the team behind Voice Control for ChatGPT
            </Text>

            <Box fontSize={"lg"}>
              <Text fontWeight={"bold"}>
                Trusted by more than 500,000 users
              </Text>
            </Box>
          </Box>
          <Image
            src="/vector/laurel-right.svg"
            alt="laurel right"
            w="50px"
            ml={3}
            opacity={0.3}
          />
        </Flex>
      </Box>

      <Section>
        <SimpleGrid
          spacing={4}
          templateColumns={{
            base: "repeat(auto-fill, minmax(100%, 1fr))",
            lg: "repeat(auto-fill, minmax(30%, 1fr))",
          }}
          width={"100%"}
        >
          <Card borderTop={"5px solid"} borderColor="brand.primary.200">
            <CardHeader>
              <Heading fontSize="xl" lineHeight="1">
                Built with Advanced AI Technology
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Talkio AI is built with advanced AI technology and lets you
                interact with the AI through voice to train your oral language
                skills.
              </Text>
            </CardBody>
          </Card>
          <Card borderTop={"5px solid"} borderColor="brand.secondary.600">
            <CardHeader>
              <Heading fontSize="xl" lineHeight="1">
                Supports Multiple Languages
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                We offer a diverse range of language options, including English,
                Chinese, French, and several other languages.
              </Text>
              <Box mt="5">
                <LanguageSelector />
              </Box>
              {/* <Select mt={4}>
                <option>All supported languages</option>
                {groupedLangList.map(([code, name, type]) => (
                  <option
                    value={code}
                    key={code}
                    disabled={type === "language"}
                  >
                    {name}
                  </option>
                ))}
              </Select> */}
            </CardBody>
          </Card>
          <Card borderTop={"5px solid"} borderColor="brand.green.400">
            <CardHeader>
              <Heading fontSize="xl" lineHeight="1">
                Premium Voice Technology
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                Talkio AI offers premium voices and supports multiple dialects
                for the most popular languages. With our advanced language
                technology, you can immerse yourself in authentic conversations
                and gain proficiency in the dialects that matter most to you.
              </Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Section>

      <Box>
        <Heading
          as="h3"
          textAlign={"center"}
          mt={10}
          mb={6}
          mx={2}
          fontWeight="thin"
          fontSize={"5xl"}
          lineHeight={"1"}
        >
          Meet the AI Tutors
        </Heading>
        <Text textAlign={"center"} mb={12}>
          Talkio AI has more than <strong>400 tutors</strong> with unique
          artificial personalities <br />
          ready to help you on your language journey
        </Text>
        <Box
          w="100%"
          overflowX={{ base: "auto", md: "hidden" }}
          bgGradient={
            "linear-gradient(to right, brand.blue.600, brand.blue.800)"
          }
        >
          <Flex
            justifyContent={{ base: "flex-start", md: "space-around" }}
            pt={10}
            pb={5}
          >
            <AvatarItem
              image="es-ve--sebastianneural-.jpeg"
              name="Sebastian"
              country="Venezuela"
            />
            <AvatarItem
              image="ja-jp--mayuneural-.jpeg"
              name="Mayu"
              country="Japan"
            />

            <AvatarItem
              image="ar-dz--ismaelneural-.jpeg"
              name="Ismael"
              country="Algeria"
            />
            <AvatarItem
              image="fr-fr--deniseneural-.jpeg"
              name="Denise"
              country="France"
            />
            <AvatarItem
              image="es-es--teoneural-.jpeg"
              name="Teo"
              country="Spain"
            />
            <AvatarItem
              image="zh-cn--xiaoqiuneural-.jpeg"
              name="Xiaoqiu"
              country="China"
            />

            <AvatarItem
              image="en-us--davisneural-.jpeg"
              name="Davis"
              country="USA"
            />
            <AvatarItem
              image="en-gb--abbineural-.jpeg"
              name="Abbi"
              country="England"
            />
            <AvatarItem
              image="pt-br--valerioneural-.jpeg"
              name="Valerio"
              country="Brazil"
            />

            <AvatarItem
              image="it-it--imeldaneural-.jpeg"
              name="Imelda"
              country="Italy"
            />

            <AvatarItem
              image="da-dk--jeppeneural-.jpeg"
              name="Jeppe"
              country="Denmark"
            />
          </Flex>
          <Text
            textAlign={"center"}
            mb={3}
            fontSize="xs"
            fontWeight={"bold"}
            fontStyle="italic"
            color="white"
          >
            And many more
          </Text>
        </Box>
      </Box>

      <Box backgroundColor="gray.100" py="12">
        <Section>
          <Flex
            alignItems={"center"}
            justifyContent="center"
            flexDir={{ base: "column", lg: "row" }}
          >
            <Flex flex="1" w="full" maxW="600px">
              <YoutubeEmbed embedId="XIoVN39w3yI" />
            </Flex>
            <Flex pl={{ lg: 12 }} flexDir={"column"} flex="1">
              <Heading
                color={"brand.gray"}
                size={{ base: "md", md: "lg" }}
                fontWeight="bold"
                mt={{ base: 4, lg: 0 }}
              >
                Learn with AI Tutors
              </Heading>
              <Text mt={{ base: 0, md: 3 }}>
                Ever wondered how it would be to have a personal language tutor
                available anytime, anywhere? At Talkio AI, we turn this dream
                into reality. Our AI Tutors are the perfect companions to
                improve your oral language skills. Powered by advanced AI
                technology, they mimic human interaction and conversation,
                offering an immersive language learning experience that is both
                engaging and effective.
              </Text>
              <Text mt="4">
                Remember, language learning is more than just grammar and
                vocabulary; it is about expressing yourself confidently. With
                Talkio
                {" AI's"} intelligent tutors, you can speak, practice, and
                conquer your language goals!
              </Text>
            </Flex>
          </Flex>
        </Section>
      </Box>

      <Box id="feature-v1" display={"none"} backgroundColor="white">
        <Features />
        <Divider />
      </Box>

      <Box id="feature-v2" backgroundColor="white">
        <Box pt={5} mt={5}>
          <Heading
            as="h3"
            textAlign={"center"}
            mt={10}
            mb={6}
            mx={2}
            fontWeight="thin"
            fontSize={"5xl"}
            lineHeight={"1"}
          >
            Feature Spotlight
          </Heading>
          <Text textAlign={"center"} mb={12} px="5">
            Navigate our suite of features, designed to turn your language
            learning into an immersive and engaging journey
          </Text>
        </Box>

        <Divider maxW="1000px" m="0 auto" />

        <FeaturePresentation
          onButtonClick={() => {
            const product = props.products?.[0];

            if (product) {
              setBillingSelection(product.id);
            }

            onSignupClick();
          }}
        />
      </Box>

      <Section>
        <Flex flexDir={"column"} flex="1">
          <Heading
            color={"brand.gray"}
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            lineHeight={1}
            mt={{ base: "50px", lg: "100px" }}
          >
            Safe and Supportive Environment
          </Heading>
          <Text mt="4" mb={{ base: 12, md: 0 }}>
            Our app provides a safe and supportive environment for you to
            practice speaking with confidence. With Talkio AI you can have
            meaningful conversations with the help of AI technology, allowing
            you to gain fluency and express yourself in the language you are
            learning.
          </Text>
        </Flex>
        <Flex flex="1" display={{ base: "none", md: "block" }}>
          <Image
            src="/landing-2.jpeg"
            alt="Woman speaking with AI"
            height={"auto"}
            alignSelf={"center"}
            width={{ base: "250px", sm: "350px", md: "450px", lg: "100%" }}
          />
        </Flex>
      </Section>

      <Box backgroundColor="gray.100">
        <Section>
          <Flex w="100%" flexDir={"column"}>
            <Heading
              as="h3"
              textAlign={"center"}
              mt={10}
              mb={6}
              mx={2}
              fontWeight="thin"
              fontSize={"5xl"}
              lineHeight={"1"}
            >
              Insights
            </Heading>
            <Text textAlign={"center"} mb={12}>
              Real stories from real users of Talkio AI
            </Text>
            <SenjaTestimonials />

            <Box
              display="none"
              maxW="600px"
              m="0 auto"
              mb="3"
              id="legacy-testimonials"
            >
              <TestimonialItem
                text="I've personally used Talkio AI to improve my Spanish, and let me tell you, it's an incredible option."
                author="Read more on terrybrock.com"
                link="https://terrybrock.com/revolutionize-your-language-learning-with-ai-technology-talkio-ai-to-the-rescue/"
                borderColor="var(--chakra-colors-brand-secondary-200)"
              />

              <TestimonialItem
                text="Talkio AI is a very impressive tool and should be included in every language learner's toolbox."
                author="One Minute English"
                link="https://oneminuteenglish.org/en/talkio-a-i-review"
                borderColor="var(--chakra-colors-brand-secondary-300)"
              />

              <TestimonialItem
                text="This tool Talkio AI is blowing my mind for language learning."
                author="Read more on x.com"
                link="https://twitter.com/markdeloura/status/1680945665923317765"
                borderColor="var(--chakra-colors-brand-secondary-400)"
              />

              <TestimonialItem
                text="Talkio AI - it's an amazing product."
                author="Master Mandarin on YouTube"
                link="https://www.youtube.com/watch?v=4CR3xWN6xik&ab_channel=MasterMandarin"
                borderColor="var(--chakra-colors-brand-secondary-500)"
              />
            </Box>
          </Flex>
        </Section>
      </Box>

      <Box py={{ base: "50px", md: "50px" }} id="faq">
        <Section>
          <Flex w="100%" flexDir={{ base: "column", md: "row" }}>
            <Box flex="1">
              <Heading size="sm" fontWeight={"bold"}>
                Frequently Asked Questions
              </Heading>
              <Text mt={2} mb={7}>
                Answers to frequent questions about Talkio AI
              </Text>
            </Box>
            <Box flex="1">
              <LandingFaq />
            </Box>
          </Flex>
        </Section>
      </Box>
      <Box backgroundColor="gray.100">
        <Box as="section">
          <Container py={{ base: "16", md: "24" }}>
            <Stack spacing={{ base: "4", md: "6" }} alignItems="center">
              <Stack spacing="3" textAlign={"center"}>
                <Heading size={{ base: "md", md: "lg" }}>Pricing</Heading>
              </Stack>
              <Text
                fontSize={{ base: "md" }}
                color="fg.muted"
                maxW="2xl"
                textAlign={"center"}
                id="pricing"
                mb={8}
              >
                Unlock your language potential with Talkio. Select the
                flexibility of a subscription or the simplicity of a one-time
                payment. Your journey to mastery starts now.
              </Text>
            </Stack>

            {props.products && (
              <PricingSwitch
                promotionCodeId={promotionCodeId}
                products={props.products}
                buttonCallback={({ productId }) => {
                  setBillingSelection(productId);
                  onSignupClick();
                }}
              />
            )}

            <Text
              textAlign={"center"}
              mt={3}
              fontSize="md"
              color="brand.gray.500"
            >
              We also offer plans for{" "}
              <Link href="/partners/schools" textDecor={"underline"}>
                schools
              </Link>{" "}
              and{" "}
              <Link href="/partners/business" textDecor={"underline"}>
                teams
              </Link>
            </Text>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
